import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { Article, WithContext } from 'schema-dts';

import {
  cacheControl,
  filterMarkets,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { getApi, markets } from '@hultafors/snickers/api';
import { useOrganization, usePageMicroData } from '@hultafors/snickers/hooks';
import {
  ContentPageFragment,
  FileField,
  GlobalFields,
  PageProps,
  SeoField,
} from '@hultafors/snickers/types';

import { createMeta } from '../../../util/create-meta';

const ContentArea = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.ContentArea),
);
const DynamicContent = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.DynamicContent),
);
const HeroBlock = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.HeroBlock),
);
const Page = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Page),
);
const RelatedArticles = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.RelatedArticles),
);
const RelatedProductsBlock = dynamic(() =>
  import('@hultafors/snickers/components').then(
    (mod) => mod.RelatedProductsBlock,
  ),
);
const Section = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Section),
);
const VideoBlock = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.VideoBlock),
);
const MicroData = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.MicroData),
);

export interface ContentPageProps extends PageProps {
  content: ContentPageFragment;
  productsApiUrl?: string;
}

export const ContentPage: NextPage<ContentPageProps> = ({
  content,
  settings,
  hrefLanguages,
}) => {
  const pageMicroData = usePageMicroData({
    content: content?.contentArea,
    seo: content.seo,
    thing: {
      '@type': 'WebPage',
      description: content?.description,
      name: content?.heroTitle,
    },
  });
  const organization = useOrganization();
  const microdata: WithContext<Article> = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    author: organization,
    description: pageMicroData.description?.toString(),
    headline: pageMicroData.name?.toString() || '',
  };
  return (
    <Page
      metadata={createMeta(content?.seo as SeoField, settings?.language?.lang)}
      hrefLanguages={hrefLanguages}
      microData={pageMicroData}
    >
      <>
        {content?.video && (
          <VideoBlock
            video={content?.video}
            posterImage={content?.videoImage}
          />
        )}
        {!content?.video && !content.hideLegacyHero && (
          <HeroBlock
            id="content-page-hero"
            alignContentLeft={content.alignContentLeft}
            doubleColorTitle={content.doubleColorTitle}
            title={content.heroTitle}
            preTitle={content.preTitle}
            image={content.image}
            mobileImage={content?.mobileImage}
            tabletImage={content?.tabletImage}
            heroIcon={content?.heroIcon}
            subTitle={content?.subTitle}
            description={content?.description}
            ctaText={content?.ctaText}
            ctaLink={content?.ctaLink}
            searchBox={content?.searchBox}
            guideIconImage={content?.iconImage as FileField}
            guideIconTitle={content?.iconInformationTitle}
            heroContentBoxBig={content?.heroContentBoxBig}
            heroContentBoxSmall={content?.heroContentBoxSmall}
            boxColor={content?.boxColor}
            sizeGuideButton={content?.sizeGuideButton}
            workWearButton={content?.workWearButton}
          />
        )}
        {content?.useContentArea && (
          <ContentArea content={content.contentArea} />
        )}
        {!content?.useContentArea && <DynamicContent content={content.feed} />}
        {!content?.useContentArea && content?.relatedProducts && (
          <Section noTop borderTop>
            <RelatedProductsBlock
              products={content.relatedProducts}
              title={content.relatedProductsHeader}
            />
          </Section>
        )}
        {!content?.useContentArea && content?.interviewPlug.length > 0 && (
          <Section noTop largePaddingTop borderTop>
            <RelatedArticles
              title={content.moreArticlesHeader || ''}
              readMoreLabel={content.linkHeader || ''}
              article={content.interviewPlug as []}
              parentPageSlug={content.parentPageSlug}
            />
          </Section>
        )}
      </>
      <MicroData data={microdata} />
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps<
  ContentPageProps,
  { slug: string; parentSlug?: string }
> = async ({ res, req, locale, params: { slug, parentSlug } }) => {
  try {
    const { settings, dato } = getApi(req, locale);

    const {
      data: { contentPage: content, ...globalFields },
    } = await dato.getContentPage(slug);

    if (!content || content === null) {
      return {
        notFound: true,
      };
    }

    if (
      parentSlug &&
      content.parentPageSlug &&
      parentSlug !== content.parentPageSlug.replace('/', '')
    ) {
      return {
        notFound: true,
      };
    }

    const hrefLanguages = getHrefLanguages(
      settings,
      filterMarkets(markets, 'SNICKERS'),
      content?.pageLocales,
    );

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        settings,
        ...(globalFields as GlobalFields),
        content,
        hrefLanguages,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
};

export default ContentPage;
